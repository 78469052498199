import { BufferGeometry, Euler, Group, Mesh, MeshBasicMaterial, Vector3 } from 'three'

import { StrandSides } from '@/types/elements/enum'
import type { DataPointMountLog, DataPointParent, DataPointSlot } from '@/types/state'

import { SetValuesData } from './BaseObject'
import DataPointPointerFactory from './DataPointPointerFactory'
import Mold from './Mold'
import PasslineCurve from './PasslineCurve'
import ThreeSensorPoint from './SensorPoint'
import Util from '../logic/Util'

export default class ThreeDataPoint<
  Slot extends BaseSlot = DataPointSlot,
  MountLog extends BaseMountLog = DataPointMountLog,
> extends ThreeSensorPoint<Slot, MountLog> {
  protected thickness: number

  private pointerArrow: Group | null = null

  private isArrowInitialized: boolean = false

  private readonly arrowLength: number = 0.5

  private readonly arrowMargin: number = this.arrowLength * 1.5

  private readonly textMargin: number = this.arrowMargin + this.arrowLength

  private readonly parentType: DataPointParent

  private readonly side?: StrandSide

  protected override readonly type: string = 'DataPoint'

  private value: string = ''

  private showValue: boolean = true

  private valueText: Mesh<BufferGeometry, MeshBasicMaterial> | null = null

  private valueTextBack: Mesh<BufferGeometry, MeshBasicMaterial> | null = null

  private readonly valueFontSize: number = 0.1

  private renderedValueUUID: string | null = null

  private iconType: 'arrow' | 'donut' | 'square' | 'triangle' | null = null

  private iconColor: number = 0xff0000

  private iconDirection: DataPointIconDirection | null = null

  private iconSize: number = 1

  public constructor (container: any, parent: any, clickableObjects: any, tooltipObjects: any) {
    super(container, parent, clickableObjects, tooltipObjects)
    this.thickness = 0

    const { type, side } = container.userData

    this.parentType = type
    this.side = side
  }

  public override updateTransform (recreateText: boolean = false): void {
    if (this.parentType === 'Segment') {
      this.handleSegmentDataPointTransform(recreateText)
    }
    else if (this.parentType === 'MoldFace') {
      this.handleMoldFaceDataPointTransform()
    }
    else if (this.parentType === 'Roller') {
      this.handleSegmentDataPointTransform(recreateText)
    }
    else if (this.parentType === 'RollerBody') {
      this.handleSegmentDataPointTransform(recreateText)
    }
    else {
      this.handleStrandDataPointTransform(recreateText)
    }
  }

  private positionText (side: string, textPosition: Vector3, textRotation: Euler) {
    if (!this.pointerArrow) {
      return
    }

    if (this.showValue) {
      if (!this.valueText) {
        return
      }

      this.valueText.position.copy(textPosition)
      this.valueText.rotation.copy(textRotation)

      if (side === 'left' || side === 'right') {
        this.valueText.rotateY(Util.RAD90)
      }

      if (this.valueTextBack) {
        this.valueTextBack.position.copy(this.valueText.position)
        this.valueTextBack.rotation.copy(this.valueText.rotation)
        this.valueTextBack.rotateX(Util.RAD180)
        this.valueTextBack.rotateZ(Util.RAD180)
      }
    }
  }

  private handleStrandDataPointTransform (recreateText: boolean = false) {
    const { sensorPoint } = this.objects

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
      recreateText,
    )

    const newPosition = new Vector3(0, 0, 0)
    const newRotation = new Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new Euler(0, 0, 0, 'XYZ')
    const size = this.sectionDetail ? this.size : 0 // add the size only in SectionView

    newSectionRotation.x = -Util.RAD90

    const { LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance
    let side: string = 'left'

    if (this.wCoord >= Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on left side
      const padding = (this.sectionDetail ? 0 : this.size) / 2

      newPosition.set(-position.z - padding, position.y + 0.00001, this.wCoord + this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceLeft.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
      side = 'left'
    }
    else if (this.wCoord <= -Mold.width / 2 && this.thickness > 0 && this.thickness < Mold.thickness) {
      // place on right side
      const padding = (this.sectionDetail ? 0 : this.size) / 2

      newPosition.set(-position.z - padding, position.y + 0.00001, this.wCoord - this.size / 2)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z - this.thickness))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
      side = 'right'
    }
    else if (this.thickness <= 0) {
      // Fixed side
      const diff = this.thickness < 0 ? this.thickness + Mold.thickness : 0 // + because this.thickness is negative

      newPosition.set(-(position.z) + size / 2, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(diff))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
      side = 'fixed'
    }
    else if (this.thickness >= Mold.thickness) {
      // Loose side
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x + size / 2), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
      side = 'loose'
    }
    else if (this.thickness <= Mold.thickness / 2) {
      newPosition.set(-position.z - this.thickness, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(-normal.z, normal.y, normal.x).setLength(NarrowFaceRight.z))
    }
    else {
      const diff = this.thickness - Mold.thickness

      newPosition.set(-(position.z + LooseSide.x), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z - diff))
      newRotation.set(0, 0, Util.RAD90 - angleX)
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    if (this.iconType && !this.isArrowInitialized && this.type === 'DataPoint') {
      this.createPointer()
      this.isArrowInitialized = true
    }
    else if (recreateText) {
      this.removePointerArrow()
      this.isArrowInitialized = false
      this.createPointer()
      this.isArrowInitialized = true
    }

    // Update arrow position and direction after transform
    if (this.pointerArrow && !this.sectionDetail) {
      this.pointerArrow.position.copy(newPosition)

      const newArrowPosition = new Vector3(0, 0, 0)
      const newArrowRotation = new Euler(0, 0, 0, 'XYZ')

      newArrowPosition.copy(sensorPoint.position)

      const textPosition = sensorPoint.position.clone()
      const textRotation = new Euler(0, 0, 0, 'XYZ')
      const normalVector = new Vector3(normal.z, -normal.y, normal.x)

      if (this.iconType === 'donut' || this.iconType === 'square') {
        switch (side) {
          case 'left':
            newArrowPosition.add(new Vector3(0, 0, this.arrowLength))
            newArrowRotation.set(0, 0, -angleX)

            // Text
            textPosition.copy(sensorPoint.position)
            textPosition.add(new Vector3(0, 0, this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'right':
            newArrowPosition.sub(new Vector3(0, 0, this.arrowLength))
            newArrowRotation.set(0, 0, -angleX)
            // Text
            textPosition.copy(sensorPoint.position)
            textPosition.sub(new Vector3(0, 0, this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'fixed':
            newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
            newArrowPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(this.arrowLength))

            // Text
            textPosition.set(-(position.z), position.y, this.wCoord)
            textPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'loose':
            newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
            newArrowPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(this.arrowLength))

            // Text
            textPosition.set(-(position.z), position.y, this.wCoord)
            textPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
        }
      }
      else {
        switch (side) {
          case 'left':
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(-angleX, -Util.RAD90, 0, 'YXZ')
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(Util.RAD90, 0, Util.RAD90 - angleX, 'ZXY')
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
              break
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(Util.RAD90, 0, -Util.RAD90 - angleX, 'ZXY')
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(0, 0, -angleX)
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.size))
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(0, 0, Util.RAD180 - angleX)
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.size))
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }

            newArrowPosition.add(new Vector3(0, 0, this.arrowMargin))

            // Text
            textPosition.copy(sensorPoint.position)
            textPosition.add(new Vector3(0, 0, this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'right':
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(-angleX, -Util.RAD90, 0, 'YXZ')
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(Util.RAD90, 0, Util.RAD90 - angleX, 'ZXY')
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(Util.RAD90, 0, -Util.RAD90 - angleX, 'ZXY')
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(0, 0, Util.RAD180 - angleX)
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(0, 0, -angleX)
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength / 2))
            }

            newArrowPosition.sub(new Vector3(0, 0, this.arrowMargin))

            // Text
            textPosition.copy(sensorPoint.position)
            textPosition.sub(new Vector3(0, 0, this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'fixed':
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(0, 0, -angleX - Util.RAD180)
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(0, 0, -angleX)
              newArrowPosition.add(normalVector.setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, 0, Util.RAD90 - angleX)
              newArrowPosition.add(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, 0, -(Util.RAD90 + angleX))
              newArrowPosition.add(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
              newArrowPosition.add(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(-angleX, -Util.RAD90, 0, 'YXZ')
              newArrowPosition.add(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength * 0.5))
            }

            newArrowPosition.sub(normalVector.setLength(this.arrowMargin))

            // Text
            textPosition.set(-(position.z), position.y, this.wCoord)
            textPosition.sub(normalVector.setLength(this.textMargin))
            textRotation.set(0, 0, -angleX)
            break
          case 'loose':
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(0, 0, -angleX)
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(0, 0, Util.RAD180 - angleX)
              newArrowPosition.sub(normalVector.setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, 0, Util.RAD90 - angleX)
              newArrowPosition.sub(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, 0, -(Util.RAD90 + angleX))
              newArrowPosition.sub(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(-angleX, -Util.RAD90, 0, 'YXZ')
              newArrowPosition.sub(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(0, 0, 1).setLength(this.arrowLength * 0.5))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(angleX, Util.RAD90, 0, 'YXZ')
              newArrowPosition.sub(normalVector.setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(0, 0, 1).setLength(this.arrowLength * 0.5))
            }

            newArrowPosition.add(normalVector.setLength(this.arrowMargin))

            // Text
            textPosition.set(-(position.z), position.y, this.wCoord)
            textPosition.add(normalVector.setLength(this.textMargin + 0.2))
            textRotation.set(0, 0, -angleX)
            break
        }
      }

      if (this.parentType !== 'Segment' && this.parentType !== 'MoldFace') {
        this.pointerArrow.position.copy(newArrowPosition)
        this.pointerArrow.rotation.copy(newArrowRotation)
      }

      this.positionText(side, textPosition, textRotation)
    }
  }

  private handleMoldFaceDataPointTransform () {
    const { sensorPoint } = this.objects
    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new Vector3(0, 0, 0)
    const newRotation = new Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new Euler(0, 0, 0, 'XYZ')
    const padding = this.sectionDetail ? this.size / 2 : 0 // add the size only in SectionView

    const { LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    if (this.side === StrandSides.Fixed) {
      const diff = this.thickness < 0 ? this.thickness + Mold.thickness : 0 // + because this.thickness is negative

      newPosition.set(-(position.z) + padding / 2, position.y + 0.00001, this.wCoord)
      newPosition.add(new Vector3(normal.z, -normal.y, normal.x).setLength(diff))
      newRotation.set(0, 0, -Util.RAD90 - angleX)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Loose) {
      newPosition.set(-(position.z + LooseSide.x + padding / 2), position.y + 0.00001, this.wCoord)
      newPosition.sub(new Vector3(normal.z, -normal.y, normal.x).setLength(LooseSide.z))
      newRotation.set(0, 0, Util.RAD90 - angleX)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Right) {
      newPosition.set(NarrowFaceRight.x - padding, position.y, position.z)
      newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
      newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
      newSectionRotation.x = -Util.RAD90
    }
    else if (this.side === StrandSides.Left) {
      newPosition.set(NarrowFaceLeft.x + padding, position.y, position.z)
      newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
      newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
      newSectionRotation.x = -Util.RAD90
    }

    // sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    // add icon if needed in the future
  }

  private handleSegmentDataPointTransform (recreateText: boolean = false) {
    const { sensorPoint } = this.objects

    const side = this.container?.userData?.['side'] ??
      this.parent?.container?.userData?.['side'] ??
      this.parent?.parent?.container?.userData?.['side'] ??
      ''

    const { position, angleX, normal } = PasslineCurve.getInfoAtPlCoord(
      this.plCoord ?? 0,
      this.sectionDetail ? true : undefined,
    )

    const newPosition = new Vector3(0, 0, 0)
    const newRotation = new Euler(0, 0, 0, 'XYZ')
    const newSectionRotation = new Euler(0, 0, 0, 'XYZ')
    const padding = this.sectionDetail ? this.size / 2 : 0 // add the size only in SectionView

    const { FixedSide, LooseSide, NarrowFaceRight, NarrowFaceLeft } = Mold.sideDistance

    switch (side) {
      case StrandSides.Fixed:
        newPosition.set(this.wCoord, position.y, position.z + FixedSide.x - padding)
        newPosition.add(normal.clone().setLength(FixedSide.z))
        newRotation.set(-Util.RAD90 - angleX, 0, 0)
        newSectionRotation.x = -Util.RAD90
        break
      case StrandSides.Loose:
        newPosition.set(this.wCoord, position.y, position.z + LooseSide.x)
        newPosition.add(normal.clone().setLength(LooseSide.z))
        newRotation.set(Util.RAD90 - angleX, 0, 0)
        newSectionRotation.y = Util.RAD180
        break
      case StrandSides.Right:
        newPosition.set(NarrowFaceRight.x - padding, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceRight.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, Util.RAD90)
        newSectionRotation.x = -Util.RAD90
        break
      case StrandSides.Left:
        newPosition.set(NarrowFaceLeft.x + padding, position.y, position.z)
        newPosition.add(normal.clone().setLength(NarrowFaceLeft.z - this.wCoord))
        newRotation.set(-Util.RAD90 - angleX, 0, -Util.RAD90)
        newSectionRotation.x = -Util.RAD90
        break
      default:
    }

    sensorPoint.position.copy(newPosition)
    sensorPoint.rotation.copy(!this.sectionDetail ? newRotation : newSectionRotation)

    if (this.iconType && !this.isArrowInitialized && this.type === 'DataPoint') {
      this.createPointer()
      this.isArrowInitialized = true
    }
    else if (recreateText) {
      this.removePointerArrow()
      this.isArrowInitialized = false
      this.createPointer()
      this.isArrowInitialized = true
    }

    if (this.pointerArrow) {
      this.pointerArrow.position.copy(newPosition)

      const newArrowPosition = new Vector3(0, 0, 0)
      const newArrowRotation = new Euler(0, 0, 0, 'XYZ')

      newArrowPosition.copy(sensorPoint.position)

      const textPosition = newArrowPosition.clone()
      const textRotation = new Euler(0, 0, 0, 'XYZ')

      if (this.iconType === 'donut' || this.iconType === 'square') {
        switch (side) {
          case StrandSides.Left:
            newArrowRotation.set(0, Util.RAD90, -angleX)
            newArrowPosition.add(new Vector3(this.arrowLength, 0, 0))

            // Text
            textPosition.add(new Vector3(1, 0, 0).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Right:
            newArrowRotation.set(0, Util.RAD90, -angleX)
            newArrowPosition.sub(new Vector3(this.arrowLength, 0, 0))

            // Text
            textPosition.sub(new Vector3(1, 0, 0).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Fixed:
            newArrowRotation.set(-angleX, 0, 0)
            // use normal
            newArrowPosition.add(new Vector3(normal.x, normal.y, normal.z).setLength(this.arrowLength))

            // Text
            textPosition.add(new Vector3(normal.x, normal.y, normal.z).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Loose:
            newArrowRotation.set(-angleX, 0, 0)
            // use normal
            newArrowPosition.sub(new Vector3(normal.x, normal.y, normal.z).setLength(this.arrowLength))

            // Text
            textPosition.sub(new Vector3(normal.x, normal.y, normal.z).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
        }
      }
      else {
        switch (side) {
          case StrandSides.Left:
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(angleX, Util.RAD180, 0, 'YXZ')
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(-angleX, 0, 0)
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, angleX, Util.RAD90, 'ZYX')
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, -angleX, -Util.RAD90, 'ZYX')
              // use cos and sin to get the correct position with angleX and this.arrowLength / 2
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(0, Util.RAD90, -angleX, 'YXZ')
              // use cos and sin to get the correct position with angleX and this.arrowLength / 2
              newArrowPosition.add(
                new Vector3(0, Math.sin(angleX) * this.arrowLength / 2, Math.cos(angleX) * this.arrowLength / 2),
              )
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(0, -Util.RAD90, angleX)
              // use cos and sin to get the correct position with angleX and this.arrowLength / 2
              newArrowPosition.sub(
                new Vector3(0, Math.sin(angleX) * this.arrowLength / 2, Math.cos(angleX) * this.arrowLength / 2),
              )
              newArrowPosition.sub(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }

            newArrowPosition.add(new Vector3(this.arrowMargin, 0, 0))

            // Text
            textPosition.add(new Vector3(1, 0, 0).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Right:
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(-angleX, 0, 0)
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(-angleX, Util.RAD180, 0)
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, angleX, Util.RAD90, 'ZYX')
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, -angleX, -Util.RAD90, 'ZYX')
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength * 0.5))
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(0, -Util.RAD90, angleX)
              // use cos and sin to get the correct position with angleX and this.arrowLength / 2
              newArrowPosition.sub(
                new Vector3(0, Math.sin(angleX) * this.arrowLength / 2, Math.cos(angleX) * this.arrowLength / 2),
              )
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(0, Util.RAD90, -angleX)
              // use cos and sin to get the correct position with angleX and this.arrowLength / 2
              newArrowPosition.add(
                new Vector3(0, Math.sin(angleX) * this.arrowLength / 2, Math.cos(angleX) * this.arrowLength / 2),
              )
              newArrowPosition.add(new Vector3(1, 0, 0).setLength(this.arrowLength / 2))
            }

            // newArrowRotation.set(-angleX, Util.RAD90, Util.RAD90)
            newArrowPosition.sub(new Vector3(this.arrowMargin, 0, 0))
            // Text
            textPosition.sub(new Vector3(1, 0, 0).setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Fixed:
            if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(0, -Util.RAD90, angleX)
              newArrowPosition.sub(new Vector3(0, 0, this.arrowLength / 2))
            }
            else if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(0, Util.RAD90, -angleX)
              newArrowPosition.add(new Vector3(0, 0, this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, Util.RAD90, Util.RAD90 - angleX)
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, Util.RAD90, -(Util.RAD90 + angleX))
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(angleX, Util.RAD180, 0, 'YXZ')
              newArrowPosition.add(new Vector3(this.arrowLength / 2, 0, 0))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(-angleX, 0, 0)
              newArrowPosition.sub(new Vector3(this.arrowLength / 2, 0, 0))
            }

            // use normal
            newArrowPosition.add(new Vector3(normal.x, normal.y, normal.z).setLength(0.5))

            // Text
            textPosition.add(normal.clone().setLength(this.textMargin + 0.2))
            textRotation.set(0, Util.RAD90, -angleX)
            break
          case StrandSides.Loose:
            if (this.iconDirection === 'awayFromMold') {
              newArrowRotation.set(0, -Util.RAD90, angleX)
              newArrowPosition.sub(new Vector3(0, 0, this.arrowLength / 2))
            }
            else if (this.iconDirection === 'towardsMold') {
              newArrowRotation.set(0, Util.RAD90, -angleX)
              newArrowPosition.add(new Vector3(0, 0, this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandNegative') {
              newArrowRotation.set(0, Util.RAD90, Util.RAD90 - angleX)
              newArrowPosition.sub(new Vector3(0, 1, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'alongStrandPositive') {
              newArrowRotation.set(0, Util.RAD90, -(Util.RAD90 + angleX))
              newArrowPosition.add(new Vector3(0, 1, 0).setLength(this.arrowLength / 2))
            }
            else if (this.iconDirection === 'acrossLeft') {
              newArrowRotation.set(angleX, Util.RAD180, 0, 'YXZ')
              newArrowPosition.add(new Vector3(this.arrowLength / 2, 0, 0))
            }
            else if (this.iconDirection === 'acrossRight') {
              newArrowRotation.set(-angleX, 0, 0)
              newArrowPosition.sub(new Vector3(this.arrowLength / 2, 0, 0))
            }

            // use normal
            newArrowPosition.sub(new Vector3(normal.x, normal.y, normal.z).setLength(0.5))

            // Text
            textPosition.sub(normal.clone().setLength(this.textMargin))
            textRotation.set(0, Util.RAD90, -angleX)
            break
        }
      }

      this.pointerArrow.position.copy(newArrowPosition)
      this.pointerArrow.rotation.copy(newArrowRotation)

      if (!side) {
        return
      }

      this.positionText(side, textPosition, textRotation)
    }
  }

  private createPointer (): Group | null {
    // Only create pointer for DataPoint type
    if (this.type !== 'DataPoint' || !this.iconType || !this.objects?.sensorPoint) {
      return null
    }

    // Remove existing pointer if any
    this.removePointerArrow()

    this.pointerArrow = DataPointPointerFactory.createPointer(
      this.iconType,
      this.container,
      this.objects.sensorPoint,
      this.iconColor,
      this.arrowLength,
    )

    if (this.showValue && this.value) {
      // Pass centerHeight=true to prevent the default upward translation
      const text = Util.getText(String(this.value), this.valueFontSize, false, true)
      const backText = Util.getText(String(this.value), this.valueFontSize, false, true)

      if (text) {
        this.container.add(text)
        this.valueText = text
        this.objects['valueText'] = text
      }

      if (backText) {
        this.container.add(backText)
        this.valueTextBack = backText
        this.objects['valueTextBack'] = backText
      }
    }

    if (this.pointerArrow) {
      this.objects['pointerGroup'] = this.pointerArrow
      this.objects['pointer'] = this.pointerArrow.children[0]

      if (this.pointerArrow.children[0]) {
        this.clickableObjects.push(this.pointerArrow.children[0])
      }
    }

    return this.pointerArrow
  }

  public removePointerArrow (): void {
    if (this.pointerArrow) {
      this.container.remove(this.pointerArrow)
      this.pointerArrow = null

      // remove from objects
      delete this.objects['pointerGroup']
      delete this.objects['pointer']
    }

    this.removeText()
  }

  private removeText (): void {
    if (this.valueText) {
      this.container.remove(this.valueText)
      this.valueText = null
      delete this.objects['valueText']
    }

    if (this.valueTextBack) {
      this.container.remove(this.valueTextBack)
      this.valueTextBack = null
      delete this.objects['valueTextBack']
    }
  }

  protected override internalSetValues (data: SetValuesData<Slot, MountLog>): void {
    this.thickness = (data.elementData.thicknessCoord ?? 0) / 1000
    this.iconType = (data.elementData as any).iconType ?? null
    this.iconColor = (data.elementData as any).iconColor ?? 0xff0000
    this.iconDirection = (data.elementData as any).iconDirection ?? 'towardsMold'
    this.iconSize = (data.elementData as any).iconSize ?? 1

    // TODO: use icon size to scale the pointer
    // eslint-disable-next-line no-console
    console.log('iconSize', this.iconSize)

    const elementData = data.elementData as any

    const renderedValueUUID = elementData.additionalData?.renderedValueUUID

    this.renderedValueUUID = renderedValueUUID

    super.internalSetValues(data)

    if (this.objects['pointer']) {
      this.objects['pointer'].userData = {
        ...this.objects['pointer'].userData,
        ...(this.objects.sensorPoint ?? {}).userData,
      }
    }
  }

  public updateValues (liveCDSData: Record<string, number>, showLiveData: boolean) {
    if (!this.renderedValueUUID) {
      return
    }

    if (!showLiveData) {
      this.removeText()

      return
    }

    const value = liveCDSData[this.renderedValueUUID]

    if (value !== undefined) {
      this.value = value.toFixed(3)
      this.showValue = true
      // Recreate text
      this.updateTransform(true)
    }
  }
}
